<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="id"
      @ok="ok" @hidden="hidden">
      
      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>
      
      <ListFilter :termOnly="true" @applyFilter="applyFilter"/>
      
      <div class="grid-toolbar border" v-if="allowManage">
        <template v-if="canAdd()">
          <span :id="`BTN_ADD_${id}`">
            <b-btn @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
            {{ $t('button.add') }}
          </b-popover>
        </template>
        <template v-if="canView()">
          <span :id="`BTN_EDIT_${id}`">
            <b-btn :disabled="disableEdit" @click="modalOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top">
            {{ $t('button.edit') }}
          </b-popover>
        </template>
        <template v-if="canAdd()">
          <span :id="`BTN_DUPLICATE_${id}`">
            <b-btn :disabled="disableDuplicate" @click="showDuplicateDialog"><font-awesome-icon :icon="['far','clone']"/></b-btn>  
          </span>
          <b-popover :target="`BTN_DUPLICATE_${id}`" triggers="hover" placement="top">
            {{ $t('button.duplicate') }}
          </b-popover>
        </template>
        <template v-if="canDelete()">
          <span :id="`BTN_DELETE_${id}`">
            <b-btn :disabled="disableDelete" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top">
            {{ $t('button.delete') }}
          </b-popover>
        </template>
        <span :id="`BTN_IMPORT_DOCUMENT_${id}`">
          <b-btn @click="fileImport"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
        </span>
        <b-popover :target="`BTN_IMPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
          {{ $t('location.button.import_document') }}
        </b-popover>
        <span :id="`BTN_EXPORT_DOCUMENT_${id}`">
          <b-btn @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
        </span>
        <b-popover :target="`BTN_EXPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
          {{ $t('location.button.export_document') }}
        </b-popover>
        <span @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
          <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'palette']"/>
            </template>
            <b-dropdown-group :header="$t('colorby')">
              <b-dropdown-item @click="onColorChange('none')" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('location')" href="#">
                <span class="action-item-label">{{ $t('location.coloring.location') }}</span><font-awesome-icon class="active-check" v-if="coloring.location" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </span>
      </div>

      <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height" id="location-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"            
            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            pagination
            :paginationPageSize="1000"
            :cacheBlockSize="10000"
            :rowData="rowData"
            rowModelType="serverSide"
            rowMultiSelectWithClick
            :rowSelection="multiple && preselected == null? 'multiple':'single'"
            :serverSideInfiniteScroll="true"
            :sideBar="false"
            suppressDragLeaveHidesColumns
            suppressCellFocus
            suppressContextMenu
            suppressMultiSort
            >
     </ag-grid-vue>

     <b-modal :title="duplicateTitle"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group :label="$t('location.field.name')" label-for="name">
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('location.field.name')"
            data-vv-name="duplicate.name"
            data-vv-delay="500"
            trim
            v-model="duplicateName"/>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicate.name') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <LocationModal :id="locationId" :show.sync="locationShow" @success="modalSuccess" :title="locationTitle"/>
    
    <b-modal :title="$t('location.confirmation.title_delete')"
        v-model="confirmDeleteModalShow"
        @ok="handleConfirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{  $t(selected.length > 1?'location.confirmation.delete_plural':'location.confirmation.delete_plural') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('location.confirmation.title_delete')"
        v-model="cannotDeleteModalShow"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{  $t('location.error.location_in_use') }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="cancel()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog :properties="[{ value: 'color', text: $t('field.color') }, { value: 'town', text: $t('location.field.town') }, { value: 'country', text: $t('location.field.country') }, { value: 'identifier', text: $t('field.identifier') }, { value: 'name', text: $t('location.field.name') }, { value: 'region', text: $t('location.field.region') }, { value: 'street', text: $t('location.field.street') }, { value: 'postcode', text: $t('location.field.postcode') }, { value: 'tag', text: $t('field.tag') }]" :mode="'LOCATION'" :show="docImportShow"
      :title="$t('location.button.import_document')"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common';
import { cloneDeep, debounce } from 'lodash';
import { strRandom, addTags, invertColor } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import ListFilter from '@/components/ListFilter/ListFilter';
import { locationService, viewProfileService, locationLinkTagService } from '@/services';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';
import RebateCellRenderer from '@/components/Aggrid/CellRenderer/Rebate';

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      locationService.list(self.buildParams(params)).then((response) => {
        self.totalRecords = response.arg_total;
        params.successCallback(response.data, response.arg_total);
        if(self.gridApi && self.modalShow) {
          self.gridApi.deselectAll();
          if(self.selected && self.selected.length > 0) {
            const selected = cloneDeep(self.selected);
            self.gridApi.forEachNode(function(node) {
              if (selected.includes(node.data.uuId)) {
                node.setSelected(true);
                selected.splice(selected.indexOf(node.data.uuId), 1);
              }
            });
          }
        }
        self.gridApi.hideOverlay();
        if (response.arg_total === 0) {
          self.showNoRowLabel(null)
        }
      })
      .catch(e => {
        params.successCallback([], 0);
        if (e != null && e.response != null && e.response.status == 403) {
          self.showNoRowLabel(self.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
      });
    }
  }
}

export default {
  name: 'LocationSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    LocationModal: () => import('@/components/modal/LocationModal.vue'),
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    ListFilter,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    /* eslint-disable vue/no-unused-components */
    'detailLinkCellRenderer': DetailLinkCellRenderer,
    'rebateCellRenderer': RebateCellRenderer,
    'colorCellRenderer': ColorCellRenderer
    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    },
    preselected: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      id: `LOCATION_LIST_${strRandom(5)}`,
      permissionName: 'LOCATION',
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      modalShow: false,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
      
      disableEdit: true,
      disableDelete: true,
      disableDuplicate: true,
      disableOk: true,

      selected: [],
      
      confirmDeleteModalShow: false,
      cannotDeleteModalShow: false,
      locationShow: false,
      locationId: null,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,
      
      searchFilter: '',

      duplicateShow: false,
      duplicateName: null,
      duplicateInProgress: false,
      
      docImportShow: false,
      deletedIds: [],
      
      coloring: {
        none: true,
        location: false
      },

      lastOpenColumnMenuParams: null,
      noRowsMessage: null,
      noRowsOverlayComponentParams: null
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    const profileKey = 'location_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const self = this;
    this.gridOptions = {
      // indicate if row is a group node
      isServerSideGroup: function (dataItem) {
          return dataItem.locationList;
      },
  
      // specify which group key to use
      getServerSideGroupKey: function (dataItem) {
          return dataItem.uuId;
      },
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.disableDuplicate = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
        }
        const cols = params.columnApi.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.columnApi.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.columnApi.moveColumn(c.colId, index);
        }

        const columns = params.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onDragStopped: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          self.gridApi.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.columnApi.getAllDisplayedColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          })
        }
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('location.location_name'),
        field: 'uuId',
        cellRenderer: 'detailLinkCellRenderer',
        checkboxSelection: true,
        lockVisible: true,
        pinned: 'left',
        minWidth: 200,
        hide: false,
        sort: 'asc',
        cellStyle: params => {
            if (params.data &&
                params.data.color &&
                self.coloring.location) {
                return { background: params.node.data.color, color: invertColor(params.node.data.color, true) };
            }
        }
      },
      {
        headerName: this.$t('location.field.town'),
        field: 'town',
        hide: false
      },
      {
        headerName: this.$t('location.field.country'),
        field: 'countryName',
        hide: false
      },
      {
        headerName: this.$t('location.field.region'),
        field: 'region'
      },
      {
        headerName: this.$t('location.field.street'),
        field: 'street'
      },
      {
        headerName: this.$t('location.field.postcode'),
        field: 'postcode'
      },
      {
        headerName: this.$t('field.tag'),
        field: 'tag',
        minWidth: 100
      },
      {
        headerName: this.$t('location.field.rebates'),
        field: 'rebates',
        cellRenderer: 'rebateCellRenderer',
        minWidth: 100
      },
      {
        headerName: this.$t('field.color'),
        field: 'color',
        cellRenderer: 'colorCellRenderer'
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier',
        minWidth: 100
      }
    ];

    const linkedEntities = [
      { selector: 'LOCATION.TAG', field: 'tag', properties: ['name'] }
    ]
    filterOutViewDenyProperties(this.columnDefs, 'LOCATION', linkedEntities);
    this.columnDefs.sort(columnDefSortFunc);

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      lockPinned: true,
      hide: true,
      menuTabs: ['columnsMenuTab']
    };
    this.autoGroupColumnDef = {
      headerName: this.$t('location.location'),
      field: 'name',
      cellRendererParams: { 
        checkbox: true,
        suppressCount: true 
      }
    };
           
    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.loadViewProfile();
  },
  created() {
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
    this.updateModalShow(this.show);
  },
  beforeDestroy() {
    this.userId = null;
    this.newToProfile = null;
    this.deletedIds = [];
  },
  watch: {
    async show(newValue) {
      if(newValue) {
        this.resetAlert();
        this.searchFilter = "";
        await this.loadViewProfile();
      } else {
        //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
        this.gridApi = null;
        this.gridColumnApi = null;
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    locationTitle() {
      return this.locationId && this.locationId.indexOf('LOCATION_NEW') == -1? this.$t('location.title_detail'): this.$t('location.title_new');
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title : this.$t('location.title_selector');
    },
    showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name');
    },
    duplicateTitle() {
      return this.$t('location.title_duplicate');
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;

      const self = this;
      const updateData = () => {
        var datasource = new ServerSideDatasource(self);
        params.api.setServerSideDatasource(datasource);
      };

      updateData();
      if (this.preselected !== null) {
        this.selected = [this.preselected];
      }      
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    handleConfirmDeleteOk() {
      this.locationDelete();
    },
    async locationDelete() {
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('location.progress.deleting');
      const selectedNodes = this.gridOptions.api.getSelectedNodes();
      const toDeleteIdNames = selectedNodes.map(node => { return { uuId: node.data.uuId, name: node.data.name != null? node.data.name : node.data.label } });
      const toDeleteIds = this.selected.map(i => { return { uuId: i } });
      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`location.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];
      
      await locationService.remove(toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('location.delete_partial');
          alertMsgDetailTitle = this.$t(`location.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = response.data[response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            const targetId = toDeleteIds[i].uuId;
            if (feedback.clue == 'OK') {
              continue;
            }
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`location.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response) {
          const response = e.response;
          if (response.status == 422) {
            alertMsgDetailTitle = this.$t(`location.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
            const feedbackList = response.data[response.data.jobCase];
            for (let i = 0, len = feedbackList.length; i < len; i++) {
              const feedback = feedbackList[i];
              if (feedback.clue == 'OK') {
                continue;
              }
              const targetId = toDeleteIds[i].uuId;
              const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
              alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
            }
          } else if (403 === response.status) {
            alertMsg = this.$t('error.authorize_action');
          }
          this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
          this.scrollToTop();
        }
      });
      
      if (alertState !== alertStateEnum.ERROR) {
        this.gridApi.refreshServerSide({ purge: true });
      }

      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        alertPayload.details = alertMsgDetailList;
        alertPayload.detailTitle = alertMsgDetailTitle;
      }
      this.deletedIds.push(...toDeleteIds);
      this.inProgressShow = false;
      this.resetAlert(alertPayload);
    },
    handleRowHovered(item, index, event) {
      event.target.lastElementChild.classList.toggle('d-none');
      event.target.lastElementChild.classList.toggle('d-flex');
    },
    handleRowUnhovered(item, index, event) {
      event.target.lastElementChild.classList.toggle('d-none');
      event.target.lastElementChild.classList.toggle('d-flex');
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
      const self = this;
      const params = {
        start: !self.exportData ? startRow : 0,
        limit: !self.exportData ? endRow - startRow + 1 : -1,
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId === 'countryName' ? 'countryCode' : sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      params.filter = this.searchFilter;
      return params;
    },
    modalOpen(isNew) {
      if(isNew) {
        this.locationId = `LOCATION_NEW_${strRandom(5)}`;
      } else {
        this.locationId = this.selected[0];
      }
      this.locationShow = true;
      this.resetAlert();
    },
    modalSuccess(payload) { 
      this.gridApi.refreshServerSide({ purge: true });
      this.resetAlert({ msg: payload.msg });
      this.scrollToTop();
    },
    async rowDelete() {
      this.resetAlert();
      const toDeleteIds = this.selected;
      const canDelete = await locationService.list({ holder: toDeleteIds}, true).then(response => {
        let ret = true;
        for (const res of response.data) {
          if (res.count > 1) {
            ret = false;
            break;
          }
        }
        return ret;
      }).catch(e => {
        // eslint-disable-next-line
        console.error(e);
      });
      
      if (canDelete) {
        this.confirmDeleteModalShow = true;
      }
      else {
        this.cannotDeleteModalShow = true;
      }
    },
    updateChildNodes() {
      if (this.gridApi.getSelectedNodes().length === 0) {
        this.hasChildNodes = false;
      }
      else {
        const node = this.gridApi.getSelectedNodes()[0];
        this.hasChildNodes = typeof node.group !== 'undefined';
      }
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.id}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    },
    ok() {
      const details = this.gridApi.getSelectedNodes().map(i => { return {uuId: i.data.uuId, name: i.data.name, rebates: i.data.rebates} });

      if(this.preselected && details.length > 0) {
        details[0].oldId = this.preselected;
        details.splice(1, details.length -1);
      }
      const ids = details.map(i => i.uuId);
      this.$emit('ok', { ids, details: details });
    },
    hidden() {
      this.alert = null;
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
      this.$emit('cancel', this.deletedIds);
    },
    openDetail(id){
      this.locationId = id;
      this.locationShow = true;
      this.resetAlert();
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    applyFilter(pattern) {
      this.searchFilter = pattern;
      this.gridApi.refreshServerSide({ purge: true });
    },
    showDuplicateDialog() {
      this.resetAlert();
      const origName = this.gridApi.getRowNode(this.selected[0]).data.name;
      this.duplicateName = `${origName} (Copy)`;
      this.duplicateShow = true;
    },
    duplicateOk() {
      this.duplicateEntity();
    },
    duplicateEntity: debounce(function() {
      this.duplicateInProgress = true;
      if(!this.duplicateName || this.duplicateName.trim().length < 1) {
        this.errors.add({
          field: `duplicate.name`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('location.field.name')])
        });
      }
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          locationService.clone(this.selected[0], { name: this.duplicateName })
          .then(() => {
            this.resetAlert({ msg: this.$t('location.duplicate') });
            this.gridApi.refreshServerSide({ purge: true });
          })
          .catch(e => {
            let  alertMsg = this.$t('error.clone.failure', [this.$t('entityType.LOCATION')]);
            if(e.response && e.response.status == 404 && e.response.data && e.response.data.jobClue != null) {
              const clue = e.response.data.jobClue.clue;
              if ('Unknown_holder' === clue) {
                alertMsg = this.$t('location.error.duplicate_not_found');
              }
              this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
              this.scrollToTop();
            } else {
              this.httpAjaxError(e);
            }
          })
          .finally(() => {
            this.duplicateShow = false;
            this.errors.clear();
            //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
            this.$nextTick(() => {
              this.duplicateInProgress = false;
            });
          });
        } else {
          this.duplicateInProgress = false;
        }
      });
    }, 100),
    duplicateCancel() {
      this.duplicateShow = false;
      this.errors.clear();
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadViewProfile() {
      const self = this;
      await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.location_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.location_selector_list);
            self.coloring.none = self.settings.location_selector_coloring ? self.settings.location_selector_coloring.none : true;
            self.coloring.location = self.settings.location_selector_coloring ? self.settings.location_selector_coloring.location : false;
          } else {
            self.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (data != null && data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(data.columnDefs);
      }
      return false;
    },
    fileImport() {
      this.docImportShow = true;
    },
    processCellCallback(/**self*/) {
      return function(params) {
        if (params.column.colId.indexOf('uuId') !== -1) {
          return params.node.data.name;
        }
        return params.value;
      }
    },
    fileExport() {
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('dataview.exporting');
      this.exportData = true;
      
      let listener = () =>{
      
        const keys = this.gridColumnApi
          .getAllColumns()
          .map(column => column.getColId())
      
        const self = this;
        this.gridApi.exportDataAsExcel({ 
          fileName: 'Locations'
          , sheetName: 'Locations'
          , columnKeys: keys
          , rowHeight: 20
          , processCellCallback: self.processCellCallback(self)
        });
       
        this.exportData = false;
        this.inProgressShow = false;
        this.gridOptions.api.removeEventListener('modelUpdated', listener);
      };
      
      this.gridApi.refreshServerSide({purge: true});
      this.gridOptions.api.addEventListener('modelUpdated', listener); 
    },
    async docImportOk({ items }) {
      this.docImportShow = false;
      this.inProgressShow = true;
      this.resetAlert();
      await this.addLocations(items);
      this.inProgressShow = false;
      this.gridApi.refreshServerSide({ purge: true });
    },
    async addLocations(items) {
      this.inProgressLabel = this.$t('location.progress.importing', [0]);
      let percentage = 0;
      for (const item of items) {
        // const country = countryCodes.filter(c => item.country === c.text);
        const data = {
          name: item.name,
          street: item.street,
          town: item.town,
          region: item.region,
          postcode: item.postcode,
          countryCode: item.country,
          identifier: item.identifier,
          color: item.color
        }
        
        const result = await locationService.create([data])
        .then(response => {
          const feedbackList = response.data.feedbackList;
          if (Array.isArray(feedbackList) && 
                feedbackList.length > 0 && 
                feedbackList[0].uuId != null) {
            return feedbackList[0].uuId;
          }
        })
        .catch((e) => {
          this.httpAjaxError(e);
          return null;
        });
        
        if (result &&
          item.tag) {
          await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), locationLinkTagService);
        }
        
        percentage++;
        this.inProgressLabel = this.$t('location.progress.importing', [parseFloat(percentage / items.length * 100).toFixed(0)]);
      
      }
    },
    docImportCancel() {
      this.docImportShow = false;
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onColorChange(val) {
      for (const key of Object.keys(this.coloring)) {
        this.coloring[key] = false;
      }
      this.coloring[val] = true;
      this.settings['location_selector_coloring'] = this.coloring;
      this.updateViewProfile();
      this.gridApi.redrawRows();
    },
    getRowColor(data) {
      if (data &&
        data.color &&
        this.coloring.location) {
        return data.color;
      }
    },
    prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage
      }
      return this.$t(`entity_selector.location_grid_no_data`)
    },
    showNoRowLabel(msg=null) {
      this.noRowsMessage = msg
      let api = this.gridOptions != null? this.gridOptions.api : null
      if (api != null) {
        this.gridOptions.api.hideOverlay()
        setTimeout(() => {
          api.showNoRowsOverlay()
        })
      }
    }
  }
}


</script>